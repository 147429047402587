import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';

import styles from './ACOTooltip.styles';

export const ACOTooltip: React.VFC<TooltipProps> = (props) => {
  const classes = styles();

  return <Tooltip arrow classes={classes} {...props}/>;
}

export default ACOTooltip;
