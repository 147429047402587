import React from 'react';

interface Props {
  vertical?: boolean;
  size?: number;
}

export const Space: React.VFC<Props> = (props) => {
  const size = props.size && props.size > 0 ? props.size : 10;
  const propKey = props.vertical ? 'width' : 'height';

  return <div style={{ [propKey]: `${size}px` }} />;
};