import { InfiniteScrolling } from '@components/InfiniteScrolling/InfiniteScrolling';
import EmptyNotifications from '@components/Notifications/EmptyNotifications';
import NotificationCard from '@components/Notifications/NotificationCard';
import styles from '@components/Notifications/Notifications.styles';
import { NotificationSkeleton } from '@components/Notifications/NotificationSkeleton';
import { useSession } from '@providers/user/SessionContext';
import { Notifications, NotificationType } from '@service/model';
import axios from 'axios';
import React from 'react';

interface NotificationsProps {
  notificationsType: NotificationType,
}

const NotificationsList: React.FC<NotificationsProps> = (props) => {

  const classes = styles();

  const name = props.notificationsType.toString();

  const render = (item) => {
    return (
      <>
        <NotificationCard {...item} />
      </>
    );
  };

  const { user } = useSession();

  const url = `/api/user/notifications/${user.id}`;
  const pageQueryName = 'page';
  const pageSizeQueryName = 'size';
  const pageSize = 10;
  const op = name === NotificationType.UNREAD ? 'gn' : 'a';


  const buildKey = (index: number) => {
    return `${url}?${pageSizeQueryName}=${pageSize}&${pageQueryName}=${index + 1}&op=${op}`;
  };

  const fetcher = (url: string) => axios.get<{
    notifications: Notifications[]
  }>(url).then((res) => res.data.notifications);

  return (
    <div>
      <InfiniteScrolling
        skeletonNode={<NotificationSkeleton />}
        buildKey={buildKey}
        fetcher={fetcher}
        renderItem={render}
        noMoreNode={'No hay más notificaciones'}
        emptyNode={<EmptyNotifications />}
        isModal={true}
        className={classes.root}
      />
    </div>
  );
};

export default NotificationsList;
