import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { NotificationType } from '@service/model';
import React from 'react';

interface FilterPastEventsProperties {
  onSelect?: (filterKey: NotificationType) => void;
}

const FilterNotifications: React.FC<FilterPastEventsProperties> = (props) => {

  const handleChange = (event, checked) => {
    const notificationType = (checked === true) ? NotificationType.UNREAD : NotificationType.ALL;

    if (props.onSelect) {
      props.onSelect(notificationType);
    }
  };

  return (
    <Box display='flex' alignItems='center' marginBottom='10px'>
      <Typography style={{ marginRight: '13px' }}>Todas</Typography>
      <FormControlLabel
        control={
          <Switch
            value='checked'
            color='primary'
            onChange={(event, checked) => handleChange(event, checked)}
            inputProps={{ 'color': '#3A3A3A' }}
          />}
        label='No leídas' />
    </Box>
  );
};

export default FilterNotifications;
