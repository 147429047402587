import { Box, Typography } from '@mui/material';
import React from 'react';

import NotificationEmptyState from '../svg/emptyNotifications.svg';

const EmptyNotifications: React.VFC = () => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' style={{ height: '200px' }}>
      <NotificationEmptyState />
      <Typography variant='h6' color='secondary'>
        Aún no hay notificaciones
      </Typography>
      <Typography variant='body1' color='textSecondary'>
        Cuando tengas una podrás verlas acá
      </Typography>
    </Box>
  );
};

export default EmptyNotifications;