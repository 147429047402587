import { UserRegisterData, UserRegisterLocationData } from '@service/model';
import React, { useContext, useState } from 'react';

interface IRegisterContext {
  data: Partial<UserRegisterData>;
  showPasswordInput: boolean;
  markSetShowPasswordInput: () => void;
  dirty: boolean;
  markDirty: () => void;

  set(key: keyof UserRegisterData, value: string | number | Date | UserRegisterLocationData);
}

const Context = React.createContext({} as IRegisterContext);

export const useRegister = () => useContext(Context);

export const RegisterProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<Partial<UserRegisterData>>({});
  const [dirty, setDirty] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(true);

  const value: IRegisterContext = {
    showPasswordInput,
    markSetShowPasswordInput: () => setShowPasswordInput(false),
    dirty,
    markDirty: () => setDirty(true),
    data,
    set: (key, value) => {
      setDirty(false);

      if (key === 'birthDate') {
        const date = new Date(value as Date);
        const month = `${date.getUTCMonth() + 1}`.padStart(2, '0');
        const day = `${date.getUTCDate()}`.padStart(2, '0');
        const strDate = `${date.getUTCFullYear()}-${month}-${day}`;
        setData((a) => ({ ...a, birthDate: strDate }));

        return;
      }

      setData((a) => ({ ...a, [key]: value }));
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
