import { Avatar } from '@components/Avatar/Avatar';
import { Box, Card, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useSession } from '@providers/user/SessionContext';
import { Notifications } from '@service/model';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import styles from './Notifications.styles';

const NotificationCard: React.FC<Notifications> = (notifications) => {
  const classes = styles();
  const additionalClass = (notifications.read) ? 'read' : 'noRead';
  const { user } = useSession();
  const { snackbars } = useCustomSnackbar();

  const router = useRouter();

  const handleOpenNotification = () => {
    //TODO: use handle to mark as read and redirect:
    // /notifications/[nid]?redirect=...

    if (!notifications.read) {
      const data = {
        notification_id: notifications.id,
      };

      axios.post<ResponseResult>(`/api/user/notifications/${user.id}`, data)
        .then((res) => {
          if (res.data.success) {
            router.push(notifications.url);
          } else {
            snackbars.showError('No se pudo cargar la notificacion');
          }
        });
    } else {
      router.push(notifications.url);
    }
  };

  return (
    <Card className={clsx(`${classes.notificationCard} ${additionalClass}`)} onClick={handleOpenNotification}>
      <Avatar src={notifications.img} alt={notifications.completeName} size={{ desktop: 64, mobile: 64 }} />
      <Box marginLeft='15px'>
        <Typography variant='subtitle1'>{notifications.completeName}</Typography>
        <Typography variant='body1'>{notifications.title}</Typography>
        <Typography variant='body2' color='primary'>Hace {notifications.date}</Typography>
      </Box>
    </Card>

  );
};

export default NotificationCard;