import Button from '@components/AcoButtons/Button';
import { a11yTabProps, Tab, Tabs } from '@components/ACOTabs';
import ACOTooltip from '@components/ACOTooltip/ACOTooltip';
import FilterNotifications from '@components/Notifications/FilterNotifications';
import MonthTickets from '@components/Notifications/MonthTickets';
import styles from '@components/Notifications/Notifications.styles';
import NotificationsList from '@components/Notifications/NotificationsList';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tooltip, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useNotificationsContext } from '@providers/notifications/NotificationsContext';
import { OnDesktop, useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { NotificationType } from '@service/model';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useState } from 'react';


const NotificationsLayout: React.FC = () => {
  const classes = styles();
  const [value, setValue] = React.useState('notificaciones');
  const [notificationsKey, setNotificationsKey] = useState<NotificationType>(NotificationType.ALL);
  const { snackbars } = useCustomSnackbar();
  const { user } = useSession();
  const { updateNotifications } = useNotificationsContext();
  const router = useRouter();
  const isMobile = useIsMobile();

  const handleTabChange = (event: React.ChangeEvent, selectedIndex: string) => {
    setValue(selectedIndex);
  };

  const allReadButton = () => {
    axios.get<ResponseResult>('/api/user/notifications/allRead')
      .then((res) => {
        if (res.data.success) {
          updateNotifications(true);

          if (isMobile) {
            snackbars.showSuccess('Todas marcadas como leídas');
          }
        } else {
          snackbars.showError('Error al marcar todas como leídas');
        }
      });
  };


  return (
    <>
      <OnDesktop>
        <Box display='flex' justifyContent='space-between' alignItems='center' padding='20px 25px'>
          <Typography variant='h6'>Notificaciones</Typography>
          <Box>
            {/*<ACOTooltip title='Activar notificaciones' arrow>
              <Button onClick={() => requestForToken(snackbars, false)}
                      className={classes.configBtn} disableRipple> <NotificationsActiveOutlinedIcon /> </Button>
            </ACOTooltip>*/}
            <ACOTooltip title='Abrir configuración' arrow>
              <Button onClick={() => router.push({ pathname: `/user/${user.id}`, query: { edit: 'true' } })}
                      className={classes.configBtn} disableRipple> <SettingsOutlinedIcon /> </Button>
            </ACOTooltip>
          </Box>
        </Box>
      </OnDesktop>
      <Box>
        <Tabs value={value} onChange={handleTabChange} variant={'fullWidth'}
              indicatorColor='primary' textColor='primary'>
          <Tab value={'notificaciones'} label='Notificaciones' disableRipple {...a11yTabProps(0)} />
          <Tab value={'consultas'} label='Consultas' disableRipple {...a11yTabProps(1)} />
        </Tabs>
      </Box>


      <Box>
        <TabContext value={value}>
          <TabPanel value={'notificaciones'} style={{ padding: ' 12px 10px 12px 24px' }}>
            <Box className={classes.boxSup}>
              <FilterNotifications onSelect={(notificationsKey) => setNotificationsKey(notificationsKey)} />
              <Box display='flex' alignItems='center' marginBottom='10px'>
                <Tooltip title='Marcar todas como leídas' arrow>
                  <Button onClick={allReadButton} className={classes.allReadBtn} disableRipple> <DraftsOutlinedIcon />
                  </Button>
                </Tooltip>
              </Box>
            </Box>
            <NotificationsList notificationsType={notificationsKey} />
          </TabPanel>
          <TabPanel value={'consultas'} style={{ padding: ' 12px 23px' }}>
            <MonthTickets />
          </TabPanel>
        </TabContext>
      </Box>


    </>
  );
};

export default NotificationsLayout;
