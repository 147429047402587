import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Autocomplete, Box, Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { UserRegisterLocationData } from '@service/model';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import React, { useEffect, useState } from 'react';

import {
  AddressComponentType,
  autocompleteService,
  GOOGLE_MAPS_API_KEY,
  loadScript,
  Place,
  PlaceType,
} from './RegisterGeolocationInput.static';

interface Props {
  onSelect: (location: UserRegisterLocationData) => void;
  onErase: () => void;
  standard?: boolean;
  defaultValue?: UserRegisterLocationData;
}

const _RegisterGeolocationInput: React.VFC<Props> = (props) => {

  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState(props.defaultValue?.address ? props.defaultValue.address : '');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);


  /**
   * Wrap into throttle the call to google service. Executed once in a window of 200 ms
   */
  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!autocompleteService.current && (window as any).google.maps) {

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      autocompleteService.places = new (window as any).google.maps.places.PlacesService(
        document.getElementById('google-maps-register'),
      );
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);

      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        if (props.defaultValue && !value) {
          results.map((result) => {
            // Normalizar las cadenas eliminando espacios y comas extras, y convirtiendo a minúsculas
            const normalizeString = (str) => {
              return str.toLowerCase().replace(/[\s,]+/g, ' ').trim();
            };

            const normalizedDescription = normalizeString(result.description);
            const normalizedAddress = normalizeString(props.defaultValue.address);

            if (normalizedDescription === normalizedAddress) {
              setValue(result);
            }
          });
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, props.defaultValue]);

  useEffect(() => {
    const placeService = autocompleteService.places;

    if (!placeService || !value || !value.place_id) {
      props.onErase();

      return;
    }

    placeService.getDetails({ placeId: value.place_id }, (result: Place) => {
      const includeType = (...types: AddressComponentType[]) =>
        result.address_components.find((ac) => types.some((t) => ac.types.includes(t)));

      const data: UserRegisterLocationData = {
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng(),
        address: result.formatted_address,
        country: includeType('country')?.long_name,
        administrativeLevel1: includeType('administrative_area_level_1')?.long_name,
        administrativeLevel2: includeType('administrative_area_level_2')?.long_name,
        locality: includeType('locality', 'sublocality_level_1')?.long_name,
      };

      props.onSelect(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Autocomplete
      id='google-maps-register'
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText='Busque su localidad'
      onChange={(event: unknown, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Ubicación'
          variant={props.standard ? 'standard' : 'outlined'}
          color='secondary'
          // className={clsx(classes.geolocationTextField, classes.textFieldSecondary)}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;

        const parts = parse(
          option.structured_formatting.main_text,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}>
                    {part.text}
                  </span>
                ))}
                <Typography variant='body2' color='text.secondary'>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export const RegisterGeolocationInput: React.VFC<Props> = (props) => {
  const loaded = React.useRef(false);
  const [gMapAPILoaded, setGMapAPILoaded] = useState(false);

  if (typeof window !== 'undefined' && !loaded.current) {

    // @ts-ignore
    window.onLoadGMap = () => {
      setGMapAPILoaded(true);
    };

    if (!document.querySelector('#google-maps-register-script')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=onLoadGMap`,
        document.querySelector('head'),
        'google-maps-register-script',
      );
    } else {
      setGMapAPILoaded(true);
    }

    loaded.current = true;
  }

  if (!gMapAPILoaded) {
    return (<></>);
  }

  return (<_RegisterGeolocationInput {...props}></_RegisterGeolocationInput>);
};
