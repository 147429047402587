import {User, Newsletter, Business, Document, OnlineEvent, Ticket, Post, ObjectType} from '@service/model';
import {NextSeo} from 'next-seo';
import {NextSeoProps} from 'next-seo/lib/types';
import React from 'react';

export interface AcoSEOProps {
    object?: User | Newsletter | Business | Document | OnlineEvent | Ticket | Post,
    nextSEO?: NextSeoProps,
}

const AcoSEO: React.VFC<AcoSEOProps> = (props) => {

    switch (props.object?.objectType) {
        case ObjectType.USER:
            const user = props.object as User;

            const SEO_USER = {
                title: `${user.name} ${user.lastName} (@${user.nickname})`,
                description: user.description,
                openGraph: {
                    url: props.object.url,
                    title: `${user.name} ${user.lastName} (@${user.nickname})`,
                    description: user.description,
                    images: [{url: user.img,
                        alt: user.completeName}],
                    profile: { firstName: user.name, username: user.nickname } }
            };

            return <NextSeo {...SEO_USER} />;

        case ObjectType.BUSINESS:
            const business = props.object as Business;

            const SEO_BUSINESS = {
                title: `${business.name} (@${business.nickname})`,
                description: business.description,
                openGraph: {
                    url: business.url,
                    title: `${business.name} (@${business.nickname})`,
                    description: business.description,
                    images: [{url: business.img,
                        alt: business.name}],
                    profile: { firstName: business.name, username: business.nickname } }
            };

            return <NextSeo {...SEO_BUSINESS} />;

        case ObjectType.NEWSLETTER:
            const newsletter = props.object as Newsletter;

            const SEO_NEWSLETTER = {
                title: `Boletín N° ${newsletter.number} Año ${newsletter.year}`,
                description: newsletter.body,
                openGraph: {
                    url: newsletter.url,
                    title: `Boletín N° ${newsletter.number} Año ${newsletter.year}`,
                    description: newsletter.body,
                }
            };

            return <NextSeo {...SEO_NEWSLETTER} />;

        case ObjectType.DOCUMENT:
            const document = props.object as Document;

            const SEO_DOCUMENT = {
                title: 'Documento',
                description: document.title,
                openGraph: {
                    url: document.url,
                    title: 'Documento',
                    description: document.title,
                }
            };

            return <NextSeo {...SEO_DOCUMENT} />;

        case ObjectType.ONLINE_EVENT:
            const onlineEvent = props.object as OnlineEvent;

            const SEO_ONLINE_EVENT = {
                title: `Evento Online: ${onlineEvent.title}`,
                description: onlineEvent.description,
                openGraph: {
                    url: onlineEvent.url,
                    title: onlineEvent.title,
                    description: onlineEvent.description,
                    images: [{url: onlineEvent.imgProgram ? onlineEvent.imgProgram : onlineEvent.thumbnails,
                        alt: onlineEvent.title}],
                }
            };

            return <NextSeo {...SEO_ONLINE_EVENT} />;

        case ObjectType.TICKET:
            const ticket = props.object as Ticket;

            const SEO_TICKET = ticket.expert.user ? {
                title: `Consulta sobre ${ticket.category.category}->${ticket.category.subcategory}`,
                description: `El experto ${ticket.expert.user.name} respondió una consulta realizada por ${ticket.user.completeName} \
sobre ${ticket.category.category}->${ticket.category.subcategory}`,
                openGraph: {
                    url: props.object.url,
                    title: `Consulta sobre ${ticket.category.category}->${ticket.category.subcategory}`,
                    description: `El experto ${ticket.expert.user.name} respondió una consulta realizada por ${ticket.user.completeName} \
sobre ${ticket.category.category}->${ticket.category.subcategory}`,
                    //TODO: agregar img de subcategoria
                    article: {publishedTime: props.object.date, authors: [ticket.expert.user.name, ticket.user.completeName],
                        section: ticket.category.category,
                        tags: [ticket.category.category, ticket.category.subcategory] }
                }
            } :
            {
                title: `Consulta sobre ${ticket.category.category}->${ticket.category.subcategory}`,
                description: `El experto ${ticket.expert.business.name} respondió una consulta realizada por ${ticket.user.completeName} \
sobre ${ticket.category.category}->${ticket.category.subcategory}`,
                openGraph: {
                    url: props.object.url,
                    title: `Consulta sobre ${ticket.category.category}->${ticket.category.subcategory}`,
                    description: `El experto ${ticket.expert.business.name} respondió una consulta realizada por ${ticket.user.completeName} \
sobre ${ticket.category.category}->${ticket.category.subcategory}`,
                    //TODO: agregar img de subcategoria
                    article: {publishedTime: props.object.date, authors: [ticket.expert.business.name, ticket.user.completeName],
                        section: ticket.category.category,
                        tags: [ticket.category.category, ticket.category.subcategory] }
                }
            } ;

            return <NextSeo {...SEO_TICKET} />;

        case ObjectType.POST:
            const post = props.object as Post;

            const SEO_POST = post.user ? {
                title: `Publicacion de ${post.user.completeName}`,
                description: post.content,
                openGraph: {
                    url: post.url,
                    title: `Publicacion de ${post.user.completeName}`,
                    description: post.content,
                    images: [{url: post.user.img,
                        alt: post.user.completeName}],
                    profile: { firstName: post.user.completeName, username: post.user.nickname } }
            } :
            {
                title: `Publicacion de ${post.business.name}`,
                    description: post.content,
                openGraph: {
                url: post.url,
                    title: `Publicacion de ${post.business.name}`,
                    description: post.content,
                    images: [{url: post.business.img,
                    alt: post.business.name}],
                    profile: { firstName: post.business.name, username: post.business.nickname } }
            };

            return <NextSeo {...SEO_POST} />;
    }

    return <NextSeo {...props.nextSEO}/>;
};

export default AcoSEO;