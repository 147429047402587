import { Skeleton } from '@mui/lab';
import { Card, CardHeader } from '@mui/material';
import React from 'react';

export const NotificationSkeleton: React.FC = () => {

  return (
    <Card>
      <CardHeader
        avatar={<Skeleton animation='pulse' style={{borderRadius: '6px'}} variant='rectangular' width={50} height={50} />}
        title={<Skeleton animation='pulse' variant='rectangular' height='16px' width='123px' style={{borderRadius: '20px', marginBottom: '6px'}} />}
        subheader={<Skeleton animation='pulse' variant='rectangular'  height='16px' width='80px' style={{borderRadius: '20px'}} />}
      />
    </Card>
  );
};
