import { useRegister } from '@components/layout/Register/context/Register.context';
import styles from '@components/layout/Register/SocialRegister.styles';
import { Box, Button, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { LoginParams, LoginStatus, useLogin } from '@providers/hooks/LoginHook';
import { GoogleOAuthProvider, TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { FacebookLogin as FacebookSVG, GoogleLogin as GoogleSVG } from '@svg/login/logos';
import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

export interface SocialRegisterProps {
  onRegister: (type?: string) => void;
  onCloseModal?: () => void;
  associate?: boolean;
  isModal?: boolean;
}

export enum SOCIAL_SOURCE {
  FACEBOOK = 1, GOOGLE
}

export interface SocialRegisterData {
  provider: SOCIAL_SOURCE;
  id: string;
  email: string;
  token: string;
  name: string;
  lastName: string;
  picture?: string;
  birthDate?: string;
}

const Register: React.VFC<SocialRegisterProps> = (props) => {
  const classes = styles();
  const router = useRouter();
  const register = useRegister();
  const { snackbars } = useCustomSnackbar();
  const { stateLogin, login } = useLogin();
  const [showError, setShowError] = useState<string>(undefined);

  const loginGoogle =
    useGoogleLogin({
      scope: 'https://www.googleapis.com/auth/userinfo.profile',
      onSuccess: tokenResponse => {handleResponseGoogle(tokenResponse)},
      onError: () => { snackbars.showError('Ocurrio un error, intente nuevamente')},
    })

  const handleResponseGoogle = (tokenResponse: TokenResponse) => {
    registerSocial({token: tokenResponse.access_token, provider: SOCIAL_SOURCE.GOOGLE})
  }

  const handleResponseFB = (res) => {
    registerSocial({token: res.accessToken, provider: SOCIAL_SOURCE.FACEBOOK})
  }

  const registerSocial = async (data) => {

    if (props.associate) {
      axios
        .put('/api/user/registerSocial', data)
        .then((res) => {

          if (res.data.success) {
            props.onRegister(data.provider == SOCIAL_SOURCE.FACEBOOK ? 'Facebook' : 'Google');
          }
          else {
            setShowError(res.data.error);
          }
        })
        .catch((err) => {
          setShowError(err);
        });
    }
    else {
      axios
        .post('/api/user/registerSocial', data)
        .then((res) => {

          const urlParams = new URLSearchParams(window.location.search);

          !urlParams.get(LoginParams.DESTINATION) && router.push({
            query: { destination: urlParams.get(LoginParams.DESTINATION) === '' ? '/' : location.pathname + location.search },
          }, undefined, { shallow: true });

          if (res.data.success) {
            //si el token esta ok o si no esta asociado a un email, logueo
            login({ token: data.token, provider: data.provider });
          }
          // NO esta registrado el email, rebota para registrarlo
          else if (res.data.error == 'noRegistrado') {
            if (data.provider === SOCIAL_SOURCE.GOOGLE && res.data.user) {
              register.set('provider', SOCIAL_SOURCE.GOOGLE);
              register.set('token', data.token);
              register.set('id', res.data.user.id);
              register.set('email', res.data.user.email);
              register.set('name', res.data.user.name);
              register.set('lastName', res.data.user.lastName);
            } else {
              register.set('provider', SOCIAL_SOURCE.FACEBOOK)
              register.set('id', res.data.user.id);
              register.set('token', data.token);
              register.set('name', res.data.user.name);
              register.set('lastName', res.data.user.lastName);
              register.set('email', res.data.user.email);
              register.set('birthDate', res.data.user.birthDate);
            }

            register.markSetShowPasswordInput();
            props.onRegister();

            setShowError(undefined);
          } else {
            setShowError(res.data.error);
          }
        })
        .catch((err) => {
          setShowError(err);
        });
    }
  }

  return (
    <Box>
      <Box display='flex'>
        <Box>
          <FacebookLogin
            appId={process.env.NEXT_PUBLIC_908028202579104}
            fields='id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
            callback={handleResponseFB}
            onFailure={errorResponse => console.error(errorResponse)}
            render={renderProps => (
              <Button
                variant='text'
                disableRipple
                onClick={renderProps.onClick}
                classes={{ root: `${classes.buttons} facebook` }}
              >
                <FacebookSVG viewBox='0 0 40 40' className={classes.socialIcon} />
                <Typography variant='subtitle1' className={classes.textButton}>
                  Facebook
                </Typography>
              </Button>
            )}
          >
          </FacebookLogin>
        </Box>
        <Box>
          <Button
            variant='text'
            disableRipple
            onClick={() => loginGoogle()}
            classes={{ root: `${classes.buttons} google` }}
          >
            <GoogleSVG viewBox='0 0 40 40' className={classes.socialIcon} />
            <Typography variant='subtitle1' className={classes.textButton}>
              Google
            </Typography>
          </Button>

        </Box>
      </Box>
      <Box display={'flex'} style={{maxWidth: '350px'}}>
        {showError && <Typography color={'red'}><b>Error:</b> {showError}</Typography>}
      </Box>

      {props.isModal && stateLogin === LoginStatus.LOGGED && props.onCloseModal()}
    </Box>
  );
};

const SocialRegister: React.VFC<SocialRegisterProps> = (props) => {

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_216972780372-89ga6k521ta5hhc9chp5n93hplm3tpcl.apps.googleusercontent.com}>
      <Register {...props} />
    </GoogleOAuthProvider>
);
};

export default SocialRegister;
