export const autocompleteService = { current: null, places: null };

export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_AIzaSyAye4BLhLDjdJqq_d_MTxmyO9DDFMWAd0g;
interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: StructuredFormatting;
}

export type AddressComponentType =
  | 'locality'
  | 'sublocality_level_1'
  | 'political'
  | 'administrative_area_level_2'
  | 'administrative_area_level_1'
  | 'country';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: AddressComponentType[];
}

interface Location {
  lat: () => number;
  lng: () => number;
}

interface Viewport {
  south: number;
  west: number;
  north: number;
  east: number;
}

interface Geometry {
  location: Location;
  viewport: Viewport;
}

interface Photo {
  height: number;
  html_attributions: string[];
  width: number;
}

export interface Place {
  address_components: AddressComponent[];
  adr_address: string;
  formatted_address: string;
  geometry: Geometry;
  icon: string;
  icon_background_color: string;
  icon_mask_base_uri: string;
  name: string;
  photos: Photo[];
  place_id: string;
  reference: string;
  types: string[];
  url: string;
  utc_offset: number;
  vicinity: string;
  html_attributions: unknown[];
  utc_offset_minutes: number;
}

export function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.setAttribute('defer', '')
  script.src = src;
  position.appendChild(script);
}
