import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { styled } from '@mui/styles';

const AcoStyledButton = styled(Button)({
  root: {
    borderRadius: '8px',
    minWidth: 'unset',
    textTransform: 'none',
    padding: '12px',
    '& > .MuiButton-label > .MuiSvgIcon-root': {
      fontSize: '16px'
    },
    '&.MuiButton-sizeLarge': {
      padding: '12px',
      '& > .MuiButton-label > .MuiSvgIcon-root': {
        fontSize: '20px'
      }
    },
    '&.MuiButton-sizeSmall': {
      padding: '8px',
      '& > .MuiButton-label > .MuiSvgIcon-root': {
        fontSize: '16px'
      }
    }
  }
});

const AcoButton = (props: ButtonProps) => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <AcoStyledButton disableElevation {...props}>
    {props.children}
  </AcoStyledButton>
);

export default AcoButton;