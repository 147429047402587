import { Tab as MUITab, Tabs as MUITabs } from '@mui/material';
import { styled } from '@mui/material/styles';


export const Tab = styled(MUITab)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: 400,
    fontSize: theme.typography.body1.fontSize,
  })
);

export const Tabs = styled(MUITabs)({
  borderBottom: '1px solid #bfbfbf',
});

export function a11yTabProps(id: number | string) {
  return {
    'id': `simple-tab-${id}`,
    'aria-controls': `simple-tabpanel-${id}`,
  };
}
