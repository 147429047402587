import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    arrow: {
      color: theme.palette.text.primary,
    },
    tooltip: {
      backgroundColor: theme.palette.text.primary,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.body1.fontWeight,
      padding: '10px 15px',
      boxShadow: '0px 1.25px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
    }
  })
);
