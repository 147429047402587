import { alpha, Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) =>
  createStyles({

    configBtn: {
      height: '40px',
      border: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
      boxSizing: 'border-box',
      minWidth: '40px !important',
      width: '40px !important',
      marginLeft: '10px',

      '&:hover': {
        background: theme.palette.secondary.main,
        color: '#FFFFFF',
      },
    },

    allReadBtn: {
      height: '30px',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      boxSizing: 'border-box',
      minWidth: '30px !important',
      width: '30px !important',

      '&:hover': {
        background: theme.palette.primary.main,
        color: '#FFFFFF',
      },
    },

    boxSup: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '15px',
    },

    notificationCard: {
      minHeight: '82px',
      padding: '9px',
      marginBottom: '8px',
      borderRadius: '8px',
      display: 'flex',
      width: '100%',

      '&.noRead': {
        background: alpha(theme.palette.primary.main, 0.1),
        border: '1px solid #26A65B',
      },

      '&.read': {
        background: '#FFFFFF',
        border: '1px solid #DCDCDC',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },

    boxConsultas: {
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      border: `2px solid ${theme.palette.primary.main}`,
      boxSizing: 'border-box',
      borderRadius: '8px',
      padding: '10px',
      minHeight: '50px',
      marginBottom: '32px',
    },

    root: {
      padding: '16px',
    },

  }));
