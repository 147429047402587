import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme) =>
  createStyles({
    buttons: {
      boxSizing: 'border-box',
      borderRadius: '8px',
      minWidth: '170px',
      marginBottom: '18px',
      marginRight: '10px',
      justifyContent: 'flex-start',
      padding: '4px 6px',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '120px',
        minHeight: '42px',
        marginBottom: '10px',
        marginRight: '10px',
      },

      '&.facebook': {
        border: `2px solid ${theme.palette.secondary.main}`,
        color: `${theme.palette.secondary.main}`,
      },

      '&.google': {
        border: `2px solid ${theme.palette.error.main}`,
        color: `${theme.palette.error.main}`,
      },
    },

    textButton: {
      letterSpacing: '0.1px',
      textTransform: 'none',
      [StyleResponsive.onDesktop]: {
        marginLeft: '16px',
      },
      [StyleResponsive.onMobile]: {
        marginLeft: '3px',
      },
    },

    socialIcon: {
      [StyleResponsive.onMobile]: {
        width: '33px',
        height: '33px',
      },
    },

  })
);