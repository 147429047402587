import styles from '@components/Notifications/Notifications.styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Box, Divider, Typography} from '@mui/material';
import { useSession } from '@providers/user/SessionContext';
import { StateMyTicketsResponse } from '@service/backend/model/responses';
import axios from 'axios';
import {useRouter} from 'next/router';
import React from 'react';
import useSWR from 'swr';

const MonthTickets: React.FC = () => {
  const classes = styles();
  const { user } = useSession();
  const router = useRouter();

  const date = new Date();

  const currentDate = date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
  }).replace(/\b([a-z])/, (s, w) => w.toUpperCase());

  const urlMonthlyTickets = `/api/user/${user.id}/monthlyTickets`;
  const fetcherMonthlyTickets = (urlMonthlyTickets: string) => axios.get<{ monthlyTickets: number }>(urlMonthlyTickets).then((res) => res.data);
  const { data: dataMonthlyTickets } = useSWR(`${urlMonthlyTickets}`, fetcherMonthlyTickets);

  const urlStateMyTickets = `/api/user/${user.id}/stateMyTickets`;
  const fetcherStateMyTickets = (urlStateMyTickets: string) => axios.get<StateMyTicketsResponse>(urlStateMyTickets).then((res) => res.data);
  const { data: dataStateMyTickets } = useSWR(`${urlStateMyTickets}`, fetcherStateMyTickets);


  return (
    <>
      { dataMonthlyTickets && !user.verified &&
      <Box className={classes.boxConsultas}>
        <Box display='flex' justifyContent='center'>
          <Typography variant='body1'
                      style={{ marginRight: '4px' }}>{`${dataMonthlyTickets.monthlyTickets === 1 ? 'Queda' : 'Quedan'}`} </Typography>
          <Typography variant='body1' color='primary'
                      style={{ marginRight: '4px' }}> {`${dataMonthlyTickets.monthlyTickets} ${dataMonthlyTickets.monthlyTickets === 1 ? 'consulta' : 'consultas'}`}</Typography>

          <Typography variant='body1' style={{ marginRight: '4px' }}> para realizar </Typography>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Typography variant='body1' style={{ marginRight: '4px' }}>durante</Typography>
          <Typography variant='body1' color='primary'> {currentDate}</Typography>
        </Box>
      </Box>
      }

      <Box>
        <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='15px'>
          <button onClick={() => router.push({pathname: `/user/${user.id}`, query: {optionProfile: 'Ticket', stateTicket: 'p'}})} style={{display: 'contents', cursor: 'pointer', color: 'red'}}>
            <Typography variant='subtitle1' color='primary'>{dataStateMyTickets ? dataStateMyTickets.stats.pendientes : '...' } pendientes</Typography>
            <KeyboardArrowRightIcon style={{ color: '#26A65B' }} />
          </button>
        </Box>
        <Divider />

        <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='10px' marginTop='10px'>
          <button onClick={() => router.push({pathname: `/user/${user.id}`, query: {optionProfile: 'Ticket', stateTicket: '3'}})} style={{display: 'contents', cursor: 'pointer', color: 'red'}}>
            <Typography variant='subtitle1' color='primary'>{dataStateMyTickets ? dataStateMyTickets.stats.esperando_respuesta : '...' } esperando aclaraciones</Typography>
            <KeyboardArrowRightIcon style={{ color: '#26A65B' }} />
          </button>
        </Box>
        <Divider />

        <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='10px' marginTop='10px'>
          <button onClick={() => router.push({pathname: `/user/${user.id}`, query: {optionProfile: 'Ticket', stateTicket: '6'}})} style={{display: 'contents', cursor: 'pointer', color: 'red'}}>
            <Typography variant='subtitle1' color='primary'>{dataStateMyTickets ? dataStateMyTickets.stats.publicadas : '...' } respondidas y publicadas</Typography>
            <KeyboardArrowRightIcon style={{ color: '#26A65B' }} />
          </button>
        </Box>
        <Divider />

        <Box display='flex' alignItems='center' justifyContent='space-between' marginTop='10px'>
          <button onClick={() => router.push({pathname: `/user/${user.id}`, query: {optionProfile: 'Ticket', stateTicket: '7'}})} style={{display: 'contents', cursor: 'pointer', color: 'red'}}>
            <Typography variant='subtitle1' color='primary'>{dataStateMyTickets ? dataStateMyTickets.stats.canceladas : '...' } canceladas</Typography>
            <KeyboardArrowRightIcon style={{ color: '#26A65B' }} />
          </button>
        </Box>
      </Box>
    </>
  );
};

export default MonthTickets;